<template>
  <div>
    <h2>Team/Kapcsolat</h2>

    <p>Mindannyian segítséget kaptunk a kapcsolatunk, a házasságunk megerősítéséhez. Tudjuk mekkora jelentősége van ennek abban, hogy ma boldogan élhetünk a párunk mellett. Szeretnénk, hogy ezek a segítő gondolatok, a Jóisten segítő keze mindenkit megérinthessen, aki megnyitja magát erre. Sokszor olyan kevés elég, hogy visszataláljunk egymáshoz, az egymásra szánt idő, figyelmesség vissza vezethet minket a fogadalmunkhoz, az <i>„egymásnak teremtettünk”</i> bizonyságához.</p>

    <p>Bármilyen kérdésetek van, forduljatok hozzánk bizalommal!</p>

    <ul class="no-justify contact-list">
      <li v-for="contact of this.contacts" :key="contact.name"> 
        <b>{{ contact.name }}</b><br> 
        e-mail: <a :href="'mailto:' + contact.email">{{ contact.email }}</a><br>
        tel.:{{ contact.phone }}
     </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'contacts',
    data: function () {
      return {
        contacts: [
          {
            name: 'Drobilich Janó és Zsófi',
            email: 'marosvet75 [kukac] gmail.com',
            phone: '20-46-828-46',
            city: 'Nagymaros'
          },
          {
            name: 'Hadi Péter és Kinga',
            email: 'Hadi.Peter [kukac] evisit.hu',
            phone: '20-977-5918',
            city: 'Nagymaros'
          },
          {
            name: 'Horváth Barnabás és Tünde',
            email: 'horvathsztunde [kukac] gmail.com',
            phone: '20-489-81-42',
            city: 'Nagymaros'
          },
          {
            name: 'Láng András és Flóra',
            email: 'BandeHUN [kukac] gmail.com',
            phone: '30-596-29-03',
            city: 'Nagymaros'
          }
        ]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
