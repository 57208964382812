<template>
  <div>
    <h2>Plakát / emléklap</h2>
    <image-galery subfolder="archive2021"></image-galery>

    <h2>"Ötször együtt"</h2>
    <p>Kitüntetett időszak ez az évben. Ilyenkor tudatosan, több időt szoktunk együtt tölteni. Bizonyára sokan mosolyogtok most, mert a vírus miatt kényszerűségből az utóbbi hónapokban sokat voltatok összezárva. Mégis, most arra bátorítunk benneteket, vágjatok bele, tegyetek szabaddá néhány órát a következő napokban! Ez a három feladat, az előadás és a szentmise segíthet a megbékélésben, a közeledésben, az egymásra vágyódásunk újraélesztésében.</p>
    <p>Ha bármilyen kérdésetek van, <router-link to="contacts">keressetek minket!</router-link><p>
    <p class="right-align">HH Team</p>

    <h2>1. "Süssünk szerelmet!"</h2>
    <div class="list-wrapper">
      <p>A mai feladatotok egy közös sütés lesz. Bármit süthettek, édeset, sósat, de savanyút azért mégse!</p>
      <p>Kérünk, csináljatok egy jó fotót az élményről. A fotón szerepeljen bármilyen módon az <strong>"IGEN"</strong> szó és akár arccal akár indirekt, de egyértelműen ki lehessen találni, kik vagytok!</p>
      <p>A zárómise végén az áldás alatt szeretnénk kivetíteni a fotókat. Várjuk a képeket a <a href="mailto:marosvet75@gmail.com">marosvet75@gmail.com</a> címre!</p>
    </div>

    <h2>2. Közös ima"</h2>
    <div class="list-wrapper">
      <p>Ma este imádkozzatok párban, közösen! Ha vannak még otthon gyerekek küldjétek el aludni őket! Gyújtsatok meg egy gyertyát, keressétek meg az esküvői feszületet, tegyétek ki az asztalra! Adjatok hálát mindenért, ami eszetekbe jut, ne sajnáljátok erre az időt most!  Hagyjátok, hogy az Úr jelenléte betöltse a szobát, a lelketeket!</p>
    
      <a href="/hh2021-ima-segedlet-Tibor-atya.pdf" download><img class="download-icon" src="../../assets/pdf-icon.png"></a>
      <p>Tibor atya írt nekünk segítséget a közös imához, bátran használjátok segítségként! Letölteni a pdf ikonra kattintva tudjátok.</p>
    </div>


    <h2>3. Előadás</h2>
    <div class="list-wrapper">
      <h3 class="font-weght-normal"><b>Pécsi Rita:</b> "<i>Férfinak és nőnek teremtve…</i>"</h3>

      <ul>
        <li><i>Uniszex? Fából vaskarika?</i></li>
        <li><i>Egy nyelvet beszélünk?</i></li> 
        <li><i>Más az agyunk is?</i></li> 
        <li><i>Problémamegoldás, szeretet, vezetés férfi-női módra?</i></li>
        <li>„<i>Egymás kedvéért születtünk</i>” (M. Aurelius)</li>
      </ul>

      <p>
        <strong>A plébánia <a href="https://www.youtube.com/channel/UC7b56OIzP9yVgMJUSxSPDVw">YouTube csatornáján</a></strong> online
        <br> február 10. szerda este 18.00
      </p>
    </div>

    <h2>4. Záró szentmise</h2>
    <div class="list-wrapper">
      <p>Házassági Fogadalom megújítása, áldás. Utána szeretetvendégség a templom mellett a szabályok betartásával, immunerősítés pogácsával és forralt borral.</p>
      <p><strong>Római Katolikus Templom</strong> <br> február 13. szombat 17.30</p>
    </div>

    <h2>5. Kirándulás</h2>
    <div class="list-wrapper">
      <p>Kérünk benneteket, kiránduljatok egyet kettesben! 3 útvonalat ajánlunk, de bátran indulhattok másik úton is. Fontos, hogy kettesben menjetek és nyissátok meg a lelketeket, evezzetek mélyre!</p>
  
      <a href="../hh2021-kirandulas-kerdesek.pdf" download><img class="download-icon" src="../../assets/pdf-icon.png"></a>
      <p>Pár kérdéssel próbálunk segíteni nektek. Jó lenne, ha hangosan is felolvasnátok egymásnak a kérdéseket, felváltva is! A pdf ikonra kattintva le is tudjátok tölteni.</p>

      <ul>
        <li><i>Mi volt a legerősebb félelem bennem a járványhelyzet miatt?</i></li>
        <li><i>Hogyan hatott a kapcsolatunkra az otthoni tanulás, home office, mit hozott és mit vett el?</i></li>
        <li><i>Idézz fel egy jó emléket a karantén időszak eseményeiből?</i></li>
        <li><i>Idézz fel egy nehéz emléket!</i></li>
        <li><i>Milyen tulajdonságai kerültek előtérbe a páromnak a karantén kapcsán? Jók és rosszak egyaránt?</i></li>
        <li><i>Mikor voltunk utoljára csak ketten? Ha rég volt, mit tudunk tenni, hogy gyakrabban találkozzunk?</i></li>
        <li><i>Van-e igényed a magányra, ha igen volt-e lehetőség rá az elmúlt időszakban?</i></li>
        <li><i>A gyerekek otthonléte hogyan hatott a szülői szerepünkre?</i></li>
        <li><i>Mondd el a legbensőbb vágyadat a közeli jövőre vonatkozóan! Miben tudok segíteni neked ebben én?</i></li>
      </ul>

      <p>Ha olyan elakadás merült fel a beszélgetés közben, amihez szakember segítségére lenne szükségetek, itt találhattok pár segítőt a közelben:</p>

      <ul>
        <li>Tóth András görög kat. pap - 30-215-3730</li>
        <li>Mármarosi Eszter - 20-340-9214</li>
        <li>Jakobszen Zsófi - 70-772-4148</li>
      </ul>

      <p>Jó utat!!!</p>
      
      <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126982348/js?mw=false" title="Gánti-szikla"></embedded-map>
      <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126980386/js?mw=false" title="Szívtipró kör"></embedded-map>
      <embedded-map src="https://www.termeszetjaro.hu/hu/embed/198695081/js?mw=false" title="Kálvária kör"></embedded-map>
    </div>
  </div>
</template>

<script>
  import EmbeddedMapComponent from './EmbeddedMapComponent.vue';
  import ImageGaleryComponent from './ImageGaleryComponent.vue';

  export default {
    name: 'archive-2021',
    components: {
      'embedded-map': EmbeddedMapComponent,
      'image-galery': ImageGaleryComponent
    },
    data: function() {
      return {
        publicPath: process.env.BASE_URL
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .download-icon {
    width: 44px;
    float: left;
    margin-top: 26px;
    margin-right: 8px;
    padding-right: 4px;
  }

  h2:first-child {
    margin-top: 20px;
  }
</style>
