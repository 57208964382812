import { render, staticRenderFns } from "./MainComponent.vue?vue&type=template&id=90157730&scoped=true"
import script from "./MainComponent.vue?vue&type=script&lang=js"
export * from "./MainComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90157730",
  null
  
)

export default component.exports