<template>
  <div>
    <h2>Feladatok</h2>

    <p>Feltöltés alatt...</p>
  </div>
</template>
<script>
  //import EmbeddedMapComponent from './subcomponents/EmbeddedMapComponent.vue'

  export default {
    name: 'trials',
    components: {
      //'embedded-map': EmbeddedMapComponent
    },
    data: function() {
      return {
        publicPath: process.env.BASE_URL
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .download-icon {
    width: 44px;
    float: left;
    margin-top: 26px;
    margin-right: 8px;
    padding-right: 4px;
  }

  .sign {
    float: left;
    width: 168px;
    margin-top: 24px;
    margin-right: 8px;
    padding-right: 4px;
  }

  @media (max-width: 920px) {
    .sign {
      width: 150px;
    }
  }
</style>