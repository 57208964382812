<template>
    <div>
      <h2>Plakát / emléklap</h2>
      <image-galery subfolder="archive2024"></image-galery>
  
      <h2>Programok</h2>
      <div>
        <h3>Kedves Párok!</h3>

        <p>
          Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire "<i>Bennünk a létra</i>" mottóval. Úgy tűnik, öregednek a szervezők, mert romantikus kedvükben 😉 
          egy romantikus túrára hívnak benneteket a kapcsolatotok kezdetére. Erről további részleteket a "Itt kezdődött" menüpont 
          alatt találtok.
        </p>

        <p>
          Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha 
          eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> 
          címre küldött e-maillel.
        </p>

        <p>
          A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében.
          Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!! Tudjuk, sokaknak nehéz megoldani a gyerekvigyázást, amikor el szeretnének jutni valamelyik
            programra. Tudunk segíteni, ez ügyben keressétek Hollai Zsófit a +36204404580-as telefonszámon!
        </p>

        <p>
          Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.
        </p>
        

        <p class="right-align">HH Team</p>
        
        <h2>1. Itt kezdődött</h2>
        <div class="list-wrapper">
          <p>
            Keressétek fel bimbózó kapcsolatotok egy vagy több ikonikus helyszínét!  Álljatok meg picit!  Bizony... 
            szabad elérzékenyülni is... Készítsetek egy SZELFIT magatokról, küldjétek el a marosvet75 [kukac] gmail.com címre! Ha találtok egy képet kettőtökről azokból 
            az évekből, kérjük azt is küldjétek el!
          </p>

          <p>
            Idei mottónk: "<i>Bennünk a létra</i>". Bizony már nem az alsó fokokat koptatjuk, de nem szabad megállnuk. Térjetek be egy templomba a kirándulásotok
            során, adjatok hálát, kérjetek, kapjatok!!! A feladatlapon kaphattok segítséget, ha nehéz. Pár sorban írjátok le imátokat!. Hozzátok majd magatokkal 
            a Közös Imádságra Február 5-én! Természetesen nem megosztást kérünk majd erről, kettőtők és az Úr szavai köztetek maradnak.
          </p>

          <a href="/flyer.jpg" download><img class="download-icon" src="../../assets/pdf-icon.png"></a>
          <p>
            Ha még nem kaptátok meg az ehhez kapcsolódó feladatlapot, vagy netán elvesztettétek, a pdf ikonra kattintva innen is le tudjátok tölteni.
          </p>
        </div>

        <h2>2. Közös imádság</h2>
        <div class="list-wrapper">
          <p>
            <strong>Nagymaros, Római Katolikus Templom</strong>
            <br> február 5. hétfő este 19.00
          </p>
        </div>

        <h2>3. Előadás</h2>
        <div class="list-wrapper">
          <p>
            <strong>Istenkapcsolatunk a házasságban:</strong>
            <br>Janka Ferenc görögkatolikus pap és felesége
          </p>
          <p>
            <strong>Nagymaros, Római Katolikus Templom</strong>
            <br> február 7. szerda este 19.00
          </p>
        </div>

        <h2>4. Házassági fogadalom megújítása</h2>
        <div class="list-wrapper">
          <p>
            <strong>Nagymaros, Római Katolikus Templom</strong>
            <br> február 10. szombat este 18.30, szentmise
          </p>
          <p>
            <strong>Nagymaros, Református Templom</strong>
            <br> február 11. vasárnap délelőtt 10.00, istentisztelet
          </p>
        </div>

        <h2>5. Gyertyafényes vacsora</h2>
        <div class="list-wrapper">
          <p>
            Idén is szervezünk gyertyafényes vacsorát, kérünk jelezzétek részvételi szándékotokat a 
            <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel. És ne feledjétek elhozni a feladatlapon említett
            különleges tárgyat sem.
          </p>
          <p>
            <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
            <br> február 10. szombat este 20.00
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import ImageGaleryComponent from './ImageGaleryComponent.vue';
  
    export default {
      name: 'archive-2024',
      components: {
        'image-galery': ImageGaleryComponent
      },
      data: function() {
        return {
          publicPath: process.env.BASE_URL
        }
      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .download-icon {
        width: 44px;
        float: left;
        margin-top: 26px;
        margin-right: 8px;
        padding-right: 4px;
    }

    .sign {
        float: left;
        width: 168px;
        margin-top: 24px;
        margin-right: 8px;
        padding-right: 4px;
    }

    @media (max-width: 920px) {
        .sign {
            width: 150px;
        }
    }
  
    h2:first-child, h3:first-child {
      margin-top: 20px;
    }
  </style>
  