<template>
  <div id="app" :class="{ 'm-nav-open': mNavOpen }">
    <div class="click-outside" v-if="mNavOpen" v-on:click="mNavOpen = false"></div>
    <div class="header">
			<div class="header-art-container">
				<svg v-on:click="mNavOpen = !mNavOpen" class="menu-toggle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path d="M0 0h24v24H0z" fill="none"/>
					<path class="menu-icon" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
				</svg>
			</div>

      <!--hr class="nav-bar-separator"-->

			<nav class="header-nav-items">
        <div class="menu-items">
          <span v-for="route in $router.options.routes" :key="route.path">
            <router-link :to="route.path" :class="{ 'has-children': route.children }">{{ route.name }}</router-link>
          </span>
        </div>
			</nav>
			
			<nav class="m-nav-items">
        <router-link v-for="route in $router.options.routes" :to="route.path" :key="route.path" :class="{ 'has-children': route.children }">{{ route.name }}</router-link>
			</nav>
		</div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data: function() {
      return {
        mNavOpen: false
      }
    },
    watch: {
      '$route.path': function() {
        this.mNavOpen = false;
      }
    }
  }
</script>

<style>
  :root {
    --background: #7d7c7c;
    --highlight: #7d7c7c;
    --primary: #000000;
    --accent: #fdcb67;
    --accent1: #fdcb67;
    --accent2: #edaa7b;
    --accent3: #c8d778;
    --text: #fdfdfd;
    --font-header: "The Brooklyn Bold", "Adjusted Arial Black", Serif;
    --font-nav-items: "The Brooklyn Bold", "Adjusted Arial", Serif;
    --font-text: Arial, Serif;
  }

  @font-face {
    font-family: "Helvetica";
    src: url('./assets/Helvetica.woff'),
         url('./assets/Helvetica.woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Helvetica Bold";
    src: url('./assets/Helvetica-Bold.woff'),
         url('./assets/Helvetica-Bold.woff2');
    font-display: block;
  }

  @font-face {
    font-family: "The Brooklyn";
    src: url('./assets/The-Brooklyn.woff2');
    font-display: block;
  }

  @font-face {
    font-family: "The Brooklyn Bold";
    src: url('./assets/The-Brooklyn-Bold.woff2');
    font-display: block;
  }

  @font-face {
    font-family: "Adjusted Arial";
    src: local(Arial);
    size-adjust: 89%;
    ascent-override: 83%;
    descent-override: 24%;
    line-gap-override: 0%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    white-space: nowrap;
    text-decoration: none;
  }

  body {
    background-color: var(--background);
    height: 100vh;
  }

  .header {
    background-color: var(--background);
  }

  .header-art-container {
      aspect-ratio: 1190 / 210;
      background-image: url('./assets/banner_2025.png');
      background-size: contain;
  }

  .nav-bar-separator {
    border: none;
    height: 2px;
    margin: 0 20px;
    background-image: linear-gradient(to right, var(--primary) 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-position-x: -33px;
    background-size: 52px 2px;
    background-repeat: repeat-x;
  }

  .header-nav-items {
    display: block;
    padding: 8px 36px;
    background-color: var(--accent);
  }

  .menu-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .header-nav-items span {
    padding: 7px 10px 7px 10px;
  }

  .header-nav-items span a {
    font-family: var(--font-nav-items);
    font-size: 1.6rem;
    color: var(--primary);

  }

  .header-nav-items span a:hover  {
    color: var(--highlight);  
  }

  .header-nav-items span a.router-link-exact-active, .header-nav-items span a.has-children.router-link-active {
    border-bottom: 3px dashed var(--primary);
  }

  .header-nav-items .filler:last-child {
    margin-right: 0;
    margin-left: 10px;
  }

  div#app {
    max-width: 1190px;
    margin: auto;
  }

  .content {
    margin: 25px 0;
    padding: 0 25px 25px 25px;
    min-height: 100vh;
  }

  .click-outside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    overflow: clip;
    background-color: #ffffff00;
  }

  .menu-toggle {
    display: none;
    width: 56px;
    height: 56px;
    fill: var(--accent);
    border: 4px solid var(--accent);
  }

  .m-nav-items {
    background-color: var(--background);
    padding: 10px;
    border: 3px solid var(--accent);
    display: none;
    position: absolute;
    right: 5px;
    margin-top: 20px;
    z-index: 10;
  }

  .m-nav-items a {
    height: 59px;
    color: var(--primary);
    background-color: var(--accent);
    width: 100%;
    text-align: center;
    padding: 20px;
    font-family: var(--font-nav-items);
    font-size: 1rem;
  }

  .m-nav-items a:not(:first-child) {
    margin-top: 5px;
  }

  .m-nav-items a:active, .m-nav-items a.has-children.router-link-active, .m-nav-items a.router-link-exact-active {
    color: var(--accent);
    background-color: var(--primary);
    border: 2px dotted var(--accent);
  }

  h1, h2 {
    font-family: var(--font-header);
    color: var(--primary);
    background-color: var(--accent);
    padding: 5px 10px;
    font-weight: normal;
  }

  h1 {
    display: block;
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;

  }

  h2:not(:first-child) {
    margin-top: 40px; 
  }

  p, ul, ol, h3 {
    font-family: var(--font-text);
    font-size: 1.375rem;
    text-align: justify;
    color: var(--text);
  }

  h3 {
    font-size: 1.5rem;
  }

  p a {
    text-decoration-line: underline;
  }

  ul, ol {
    margin-left: 25px;
    list-style-position: outside;
  }

  .highlight {
    color: var(--accent);
    border: 2px solid var(--accent);
    padding: 10px 20px;
  }

  .no-justify {
    text-align: left;
  }

  .right-align {
    width: 100%;
    text-align: right;
  }

  .content a {
    color: var(--text);
  }

  p:not(:first-child), ul:not(:first-child), ol:not(:first-child), h3:not(:first-child) {
    margin-top: 20px;
  }

   li:not(:first-child) {
     margin-top: 5px;
   }

   p {
     line-height: 2rem;
   }

  h3 {
    display: block;
  }

  ul.contact-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: space-between;
    margin: 0;
  }

  ul.contact-list > li {
    border: 1px solid var(--text);
    padding: 20px;
    text-align: center;
    width: 49%;
    margin-top: 12px;
    line-height: 2rem;
  }

  .font-weght-normal {
    font-weight: normal;
  }

  .list-wrapper {
    border: 1px solid var(--text);
    padding: 20px;
    margin-top: 20px;
  }

  @media (min-width: 1210px) {
    .content {
      border-left: 2px solid var(--accent);
      border-right: 2px solid var(--accent);
    }
  }


  @media (max-width: 920px) {
	p, li {
      font-size: 1.125rem;
    }
	
    h2 {
      font-size: 1.3125rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p, ul.contact-list > li {
      line-height: 1.75rem;
    }
  }

  @media (max-width: 840px) {
    .nav-bar-separator {
      display: none;
    }

    .content {
      margin-top: 0;
      padding-top: 20px;
      border-top: 14px solid var(--accent);
    }

    .header-nav-items {
      display: none;
    }
    
    .header-art-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-art {
      margin-left: -38px;
    }
    
    .menu-toggle {
      display: block;
      position: absolute;
      right: 20px;
      background-color: var(--background);
    }
    
    div#app.m-nav-open .m-nav-items {
      display: flex;
      flex-direction: column;
    }

    div#app.m-nav-open .menu-toggle {
      background-color: var(--primary);
    }

    ul.contact-list li {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .menu-toggle {
      width: 48px;
      height: 48px;
    }
  }

  @media (max-width: 450px) {
    .menu-toggle {
      width: 45px;
      height: 45px;
    }
  }
</style>
