<template>
  <div>
    <h3>Kedves Párok!</h3>

    <p>
      Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire "<i>Színezd újra</i>" jeligével. A részletekkel hamarosan jelentkezünk. Addig is tekintsétek meg alább a tervezett programokat.
    </p>

    <p class="right-align">HH Team</p>

    <h2>Közös imádság</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 10. hétfő este 20.00, csendes szentségimádás
      </p>
    </div>

    <h2>Házassági fogadalom megújítása</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 15. szombat este 18.00, záró szentmise
      </p>
      <p>
        <strong>Nagymaros, Református Templom</strong>
        <br> február 16. vasárnap délelőtt 10.00, istentisztelet
      </p>
    </div>

    <h2>Gyertyafényes vacsora</h2>
    <div class="list-wrapper">
      <p>
        Idén is szervezünk gyertyafényes vacsorát, kérünk jelezzétek részvételi szándékotokat a 
        <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel.
      </p>
      <p>
        <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
        <br> február 15. szombat este 19.00
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'main'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>